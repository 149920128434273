// Main exports

// Character ID Mappings
export const characterMappings = {
  'triple_zero': 'triplezero',
  'fifty_rt': '50rt',
  'arc_trooper': 'trooperclone_arc',
  'aayla_secura': 'aaylasecura',
  'admiral_ackbar': 'ackbaradmiral',
  'admiral_piett': 'piett',
  'admiral_raddus': 'admiralraddus',
  'admiral_trench': 'admiraltrench',
  'ahsoka_fulcrum': 'ahsokaadult',
  'ahsoka_snips': 'ahsoka',
  'amilyn_holdo': 'holdo',
  'asajj_ventress': 'ventress',
  'aurra_sing': 'aurrasing',
  'b1_battle_droid': 'b1',
  'b2_super_battle_droid': 'b2',
  'bb_8': 'bb8',
  'battle_droid_1': 'bt1',
  'barriss_offee': 'barriss_light',
  'bastila_shan': 'bastilashan',
  'bastila_fallen': 'bastilashan_dark',
  'baylan_skoll': 'baylanskoll',
  'baze_malbus': 'bazemalbus',
  'ben_solo': 'bensolo',
  'biggs': 'rebelpilot_biggs',
  'bistan': 'bistan',
  'bo_katan_mandalore': 'mandalorbokatan',
  'bo_katan_kryze': 'bokatan',
  'boba_fett': 'bobafett',
  'boba_fett_scion': 'bobafettold',
  'bodhi_rook': 'bodhi',
  'boss_nass': 'bossnass',
  'bossk': 'bossk',
  'boushh_leia': 'boushh',
  'c3po': 'c3po',
  'cc_2224_cody': 'trooperclone_cody',
  'ct_21_0408_echo': 'trooperclone_echo',
  'ct_5555_fives': 'trooperclone_fives',
  'ct_7567_rex': 'trooperclone_rex',
  'cad_bane': 'cadbane',
  'cal_kestis': 'calkestis',
  'canderous_ordo': 'canderous',
  'captain_drogan': 'captaindrogan',
  'captain_enoch': 'captainenoch',
  'captain_han_solo': 'hoth_han',
  'captain_phasma': 'phasma',
  'captain_rex': 'captainrex',
  'captain_tarpals': 'captaintarpals',
  'cara_dune': 'caradune',
  'carth_onasi': 'carth',
  'cassian_andor': 'cassian',
  'cera_junda': 'cerejunda',
  'chewbacca_ot': 'chewbacca_ot',
  'chief_chirpa': 'ewok_chirpa',
  'chief_nebit': 'jawa_nebit',
  'chirrut_imwe': 'chirrut',
  'chopper': 'chopper',
  'clone_sergeant': 'trooperclonegreen',
  'clone_chewbacca': 'chewbacca',
  'colonel_stark': 'colonel_stark',
  'commander_ahsoka': 'commanderahsokatano',
  'lukebespin': 'lukebespin',
  'coruscant_police': 'coruscantpolice',
  'count_dooku': 'dooku',
  'old_daka': 'daka',
  'dark_trooper': 'darktrooper',
  'dark_trooper_moff': 'moffgideons3',
  'darth_bane': 'darthbane',
  'darth_malak': 'darthmalak',
  'darth_malgus': 'darthmalgus',
  'darth_maul': 'maul',
  'darth_nihilus': 'nihilus',
  'darth_revan': 'sithrevan',
  'darth_sidious': 'sidious',
  'darth_sion': 'sion',
  'darth_talon': 'darthtalon',
  'darth_traya': 'traya',
  'darth_vader': 'vader',
  'dash_rendar': 'dashrendar',
  'dathcha': 'jawa_dathcha',
  'death_trooper_peridea': 'deathtrooperperidea',
  'death_trooper': 'trooperdeath',
  'dengar': 'dengar',
  'director_krennic': 'krennic',
  'doctor_aphra': 'doctoraphra',
  'droideka': 'droideka',
  'echo': 'bb_echo',
  'eeth_koth': 'eethkoth',
  'eighth_brother': 'eighthbrother',
  'embo': 'embo',
  'emperor_palpatine': 'palpatineemperor',
  'enfy_nest': 'enfys',
  'ewok_elder': 'ewok_chief',
  'ewok_scout': 'ewok_scout',
  'ezra_bridger': 'ezra_s3',
  'ezra_exile': 'ezraexile',
  'fennec_shand': 'fennec',
  'fifth_brother': 'fifthbrother',
  'finn': 'finnjakku',
  'fo_executioner': 'firstorder_executioner',
  'fo_officer': 'firstorderofficer',
  'fo_sf_tie_pilot': 'firstorder_pilot',
  'fo_stormtrooper': 'firstordertrooper',
  'fo_tie_pilot': 'firstordertiepilot',
  'gamorrean_guard': 'gamorreanguard',
  'gar_saxon': 'gar_saxon',
  'garazeb_zeb': 'zeb_s3',
  'general_grievous': 'grievous',
  'general_hux': 'generalhux',
  'general_kenobi': 'obiwangeneral',
  'general_skywalker': 'generalanakin',
  'general_veers': 'veers',
  'geonosian_brood_alpha': 'geonosian_broodalpha',
  'geonosian_soldier': 'geonosian_soldier',
  'geonosian_spy': 'geonosian_spy',
  'grand_admiral_thrawn': 'thrawn',
  'grand_inquisitor': 'grandinquisitor',
  'grand_master_yoda': 'yodagrandmaster',
  'grand_moff_tarkin': 'tarkinadmiral',
  'great_mothers': 'greatmothers',
  'greef_karga': 'greefkarga',
  'greedo': 'greedo',
  'gungan_bombardier': 'gunganboomadier',
  'gungan_phalanx': 'gunganphalanx',
  'hk_47': 'hk47',
  'han': 'han',
  'hera_syndulla': 'hera_s3',
  'hermit_yoda': 'yodahermit',
  'hondo_ohnaka': 'hondoohnaka',
  'hoth_rebel_scout': 'rebelhothscout',
  'hoth_rebel_soldier': 'rebelhoth',
  'hunter': 'bb_hunter',
  'ig_100_magnaguard': 'magnaguard',
  'ig_11': 'ig11_nurse',
  'ig_12_grogu': 'ig12',
  'ig_86': 'ig86',
  'ig_88': 'ig88',
  'iden_versio': 'idenversioempire',
  'ima_gun_di': 'imagundi',
  'imperial_probe_droid': 'probedroid',
  'imperial_super_commando': 'imperial_super_commando',
  'jabba_the_hutt': 'jabbathehutt',
  'jango_fett': 'jangofett',
  'jar_jar_binks': 'jarjarbinks',
  'jawa': 'jawa_jawa',
  'jawa_engineer': 'jawa_engineer',
  'jawa_scavenger': 'jawa_scavenger',
  'jedi_consular': 'jedi_consular_03',
  'jedi_knight_anakin': 'anakinjedi',
  'jedi_knight_guardian': 'jedi_guardian_01',
  'jedi_knight_cal_kestis': 'jediknightcal',
  'jedi_knight_luke': 'luke_jediknight',
  'jedi_knight_revan': 'jedirevan',
  'jedi_master_kenobi': 'globiwan',
  'jedi_master_luke': 'luke_jml',
  'jolee_bindo': 'joleebindo',
  'juhani': 'juhani',
  'jyn_erso': 'jyn',
  'k_2so': 'k2so',
  'kanan_jarrus': 'kanan_s3',
  'kelleran_beq': 'kelleranbeq',
  'ki_adi_mundi': 'kiadimundi',
  'kit_fisto': 'kitfisto',
  'krrsantan': 'krrsantan',
  'kuiil': 'kuiil',
  'kyle_katarn': 'kylekatarn',
  'kylo_ren': 'kyloren',
  'kylo_ren_unmasked': 'kylo_unmasked',
  'l3_37': 'l337',
  'lando_calrissian': 'landobespin',
  'leia_organa': 'leiaendor',
  'lobot': 'lobot',
  'logray': 'ewok_logray',
  'lord_vader': 'lordvader',
  'luke_farmboy': 'luke_ep4',
  'luminara_unduli': 'luminara',
  'luthen_rael': 'luthenrael',
  'mace_windu': 'macewindu',
  'magmatrooper': 'trooperstorm_magma',
  'mara_jade': 'marajade',
  'marrok': 'marrok',
  'master_qui_gon': 'masterquigon',
  'maul': 'maul_cyborg',
  'merrin': 'merrin',
  'mission_vao': 'mission',
  'mob_enforcer': 'mob_enforcer',
  'moff_gideon': 'moffgideon',
  'mon_mothma': 'monmothma',
  'morgan_elsbeth': 'morganelsbeth',
  'mother_talzin': 'nightsisters_talzin',
  'night_trooper': 'nighttrooper_a',
  'nightsister_acolyte': 'nightsister_acolyte',
  'nightsister_initiate': 'nightsister_initiate',
  'nightsister_spirit': 'nightsisters_wraith',
  'nightsister_zombie': 'nightsisters_zombie',
  'ninth_sister': 'ninthsister',
  'nute_gunray': 'nutegunray',
  'obi_wan_old_ben': 'obiwanep4',
  'omega': 'badbatchomega',
  'padawan_obiwan': 'padawanobiwan',
  'padawan_sabine': 'padawansabine',
  'padme_amidala': 'padme_geonosis',
  'pao': 'pao',
  'paploo': 'ewok_paploo',
  'paz_vizsla': 'pazvizsla',
  'plo_koon': 'plokoon',
  'poe_dameron': 'poe',
  'poggle_the_lesser': 'geonosian_poggle',
  'princess_kneesaa': 'princesskneesaa',
  'princess_leia': 'leia_princess',
  'qira': 'qira',
  'queen_amidala': 'queenamidala',
  'qui_gon_jinn': 'quigon',
  'r2_d2': 'r2d2',
  'range_trooper': 'trooperranger',
  'rebel_officer_leia': 'leiahoth',
  'resistance_hero_finn': 'finn',
  'resistance_hero_poe': 'poe_tros',
  'resistance_pilot': 'resistancepilot',
  'resistance_trooper': 'resistancetrooper',
  'rey_gl': 'reyjakku',
  'rey_jedi_training': 'rey_tlj',
  'rey_scavenger': 'rey_tros',
  'rose_tico': 'rose',
  'royal_guard': 'royalguard',
  'sabine_wren': 'sabine_s3',
  'sana_starros': 'sanastarros',
  'savage_opress': 'savageopress',
  'saw_gerrera': 'sawgerrera',
  'scarif_rebel_pathfinder': 'rebel_scarif',
  'scout_trooper': 'trooperscout',
  'second_sister': 'secondsister',
  'seventh_sister': 'seventhsister',
  'shaak_ti': 'shaakti',
  'shin_hati': 'shinhati',
  'shoretrooper': 'troopershore',
  'sith_assassin': 'sithassassin',
  'sith_empire_trooper': 'sithtrooper',
  'sith_eternal_emperor': 'espalpatine_pre',
  'sith_marauder': 'sithmarauder',
  'sith_trooper': 'firstorder_sithtrooper',
  'skiff_guard_lando': 'undercoverlando',
  'snowtrooper': 'troopersnow',
  'stap' : 'stap',
  'starkiller': 'starkiller',
  'stormtrooper': 'trooperstorm',
  'stormtrooper_han': 'trooperstorm_han',
  'sun_fac': 'geonosian_sunfac',
  'supreme_leader_kylo': 'kyloren_tros',
  't3_m4': 't3m4',
  'talia': 'talia',
  'tarfful': 'tarfful',
  'taron_malicos': 'taronmalicos',
  'tech': 'bb_tech',
  'teebo': 'ewok_teebo',
  'the_armorer': 'armorer',
  'the_mandalorian': 'mandalorian',
  'the_mandalorian_beskar': 'mandobeskar',
  'third_sister': 'thirdsister',
  'chewbacca_c3po': 'chewbacca_c3po',
  'tusken_chieftain': 'tuskenchieftain',
  'tusken_raider': 'tuskenraider',
  'tusken_shaman': 'tuskenshaman',
  'tusken_warrior': 'tuskenhuntress',
  'urorrurrr': 'urorrurrr',
  'ugnaught': 'ugnaught',
  'vandor_chewbacca': 'chewbacca_vandor',
  'veteran_smuggler_han': 'tfa_han',
  'visas_marr': 'visas',
  'wampa': 'wampa',
  'wat_tambor': 'wattambor',
  'wedge_antilles': 'rebelpilot_wedge',
  'wicket': 'ewok_wicket',
  'wrecker': 'bb_wrecker',
  'young_han_solo': 'han_young',
  'young_lando': 'younglando',
  'zaalbar': 'zaalbar',
  'zam_wesell': 'zamwesell',
  'zorii_bliss': 'zoriibliss'
} as const;

export const shipMappings = {
  // Capital Ships
  'chimaera': 'chimaera', 
  'endurance': 'venator', 
  'executor': 'executor',
  'executrix': 'stardestroyer',
  'finalizer': 'finalizer',
  'home_one': 'moncalamarilibertycruiser',
  'profundity': 'profundity',
  'leviathan': 'leviathan',
  'negotiator': 'negotiator',
  'malevolence': 'malevolence',
  'furyclassinterceptor': 'furyclassinterceptor',

  // Regular Ships
  'ahsoka_tanos_jedi_starfighter': 'jedi_fighter_ahsoka',
  'anakins_eta2': 'jedi_fighter_anakin',
  'b28': 'b28extinctionclassbomber',
  'btl_b_y_wing': 'ywing_btlb',
  'biggs_darklighter_x_wing': 'xwing_red3',    
  'bistan_u_wing': 'uwing',
  'cassians_u_wing': 'uwing_hero',
  'clone_sergeants_arc_170': 'arc170',
  'comeuppance': 'comeuppance', 
  'ebon_hawk': 'ebonhawk',
  'emperors_shuttle': 'imperialshuttle',
  'first_order_sf_tie_fighter': 'fosf_tie_fighter',
  'first_order_tie_fighter': 'firstorder_tiefighter',
  'fury_class_interceptor': 'furyclassinterceptor',
  'fives_umbaran_starfighter': 'umbaran_star_fighter',
  'gauntlet_starfighter': 'gauntlet',
  'geonosian_soldier_starfighter': 'geonosis_fighter_soldier',
  'geonosian_spy_starfighter': 'geonosis_fighter_spy',
  'ghost': 'ghost',
  'hans_millennium_falcon': 'mfalcon',
  'hounds_tooth': 'houndstooth',
  'hyena_bomber': 'hyenabomber',
  'ig2000': 'ig2000',
  'imperial_tie_bomber': 'tiebomber',
  'imperial_tie_fighter': 'tiefighter',
  'jedi_consulars_starfighter': 'jedi_fighter',
  'kylo_ren_command_shuttle': 'upsilon_shuttle_kylo',    
  'lando_millennium_falcon': 'mil_fal_pristine',    
  'mg_100_starfortress': 'mg100',    
  'marauder': 'marauder',    
  'mark_v1_interceptor': 'sithsupremacyclass',    
  'outrider': 'outrider', 
  'phantom' : 'phantom2',    
  'plo_koons_jedi_starfighter': 'jedi_fighter_bladeofdorin',
  'razor_crest': 'razorcrest',
  'rexs_arc_170': 'arc170_02',
  'scimitar': 'sithinfiltrator',
  'sithsupremacyclass': 'sithsupremacyclass',
  'slave_1': 'slave1',
  'sun_facs_geonosian_starfighter': 'geonosis_fighter_sunfac',
  'tie_advanced_x1': 'tieadvanced',
  'tie_interceptor': 'tie_interceptor_prototype',
  'umbaran_starfighter': 'umbaran_star_fighter',
  'vulture_droid': 'vulturedroid',
  'xanadu_blood': 'xanadublood',
  'rebel_y_wing': 'ywing'
} as const;

// Debug mode för loggning
const DEBUG = true;

const logDebug = (message: string, ...args: any[]) => {
  if (DEBUG) {
    console.debug(`[ImageMapping] ${message}`, ...args);
  }
};

// Funktion för att normalisera ID
export const normalizeId = (name: string): string => {
  if (!name) return '';
  
  return name
    .toLowerCase()
    .replace(/[']/g, '') // Ta bort apostrofer
    .replace(/[^a-z0-9_\s-]/g, '') // Behåll bara bokstäver, siffror, _, mellanslag och -
    .trim()
    .replace(/[\s-]+/g, '_'); // Ersätt mellanslag och - med _
};

// Funktion för att hämta filnamn
export function getImageFileName(id: string, type: string): string {
  if (!id) {
    logDebug('No ID provided for image');
    return '';
  }
  
  const normalizedId = normalizeId(id);
  logDebug(`Normalized ID: ${normalizedId} from ${id}`);
  
  // Välj rätt mappning
  const mappings = type.includes('ship') ? shipMappings : characterMappings;
  
  // Hämta filnamn från mappning
  const fileName = mappings[normalizedId as keyof typeof mappings];
  
  if (!fileName) {
    logDebug(`No mapping found for ID: ${id}, normalized: ${normalizedId}, type: ${type}`);
    logDebug('Available mappings:', Object.keys(mappings));
    return normalizedId; // Fallback till normaliserat ID
  }
  
  return fileName;
}